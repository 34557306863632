import React, { Component } from "react";
import classnames from "classnames";
import classNames from "classnames";
import projectOne from "../assets/web/jpeg/image1.jpg";
import projectTwo from "../assets/web/jpeg/image2.jpg";
import rapidProject from "../assets/web/jpeg/image8.jpg";
// import projectThree from "../assets/web/jpeg/esolnet_mob.jpg"
// import projectFour from "../assets/web/jpeg/image4.jpg"
import projectSix from "../assets/web/jpeg/image7.jpg";
import marvelGame from "../assets/web/jpeg/marvel.jpg";
import movieSpy from "../assets/web/jpeg/movies.jpg";
import Layout from "../components/layout";
import SEO from "../components/seo";

import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";

import style from "./web.module.css";

const loadingGif = require(`../assets/loading-large.gif`);

class Web extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Web Portfolio"
          description="A Few Web Projects and Git Repositories mostly based on Frontend Work"
          keywords="Karpasitis, Andreas, Web, Developer, Software, Frontend, React, HTML, CSS, Javascript, Barcelona"
          url="https://www.karpasitis.net/web"
        />
        <div className={style.container}>
          <header>
            <h1 className={style.title}>Web Development</h1>
          </header>
          <p>
            Building web pages and landing pages, using React or Word Press.
            Experience with SEO updates and issues, page speed improvements and
            auditing.
          </p>
          <p>
            Extensive experience with <b>React</b>, <b>Javascript/Typescript</b>
            , <b>NodeJS</b>, <b>MongoDB</b> and <b>AWS</b>. Design and UI skills
            to produce better looking products.
          </p>
          <p>
            Currently working remotely as a contractor at a UK company focusing
            on improving access to mental health
          </p>
          <div className={style.accounts}>
            You can also visit my{" "}
            <a
              href="http://www.gitlab.com/akarpasitis"
              target="_blank"
              rel="noopener noreferrer"
            >
              Gitlab Account
            </a>{" "}
            or{" "}
            <a
              href="http://www.github.com/akarpas"
              target="_blank"
              rel="noopener noreferrer"
            >
              GitHub Account
            </a>
          </div>
          <br />
          <h3
            style={{
              marginBottom: "0px",
              borderTop: "1px solid gray",
              width: "80vw",
              maxWidth: "60vw",
              textAlign: "center",
              paddingTop: "24px",
            }}
          >
            Sneak Peek:
          </h3>
          <div className={style.gridContainer}>
            <div className={classNames(style.item1, style.gridCell)}>
              <a
                href="https://karpasitis.netlify.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={loadingGif}
                  data-src={projectOne}
                  data-size="auto"
                  className={classnames(style.image, "lazyload")}
                  alt="Project 1"
                />
              </a>
              <h3 className={style.title}>Portfolio Website</h3>
              <p className={style.text}>
                Personal Portfolio Website done in ReactJS and CSS Grid
              </p>
              <a
                href="https://karpasitis.netlify.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                View
              </a>
            </div>
            <div className={classNames(style.item2, style.gridCell)}>
              <a
                href="https://rapidshift.cy"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={loadingGif}
                  data-src={rapidProject}
                  data-size="auto"
                  className={classnames(style.image, "lazyload")}
                  alt="Rapid Shift"
                />
              </a>
              <h3 className={style.title}>Rapid Shift</h3>
              <p className={style.text}>
                A website for a digital marketing agency. Built on vanilla
                JS/HTML/CSS
              </p>
              <a
                href="https://rapidshift.cy"
                target="_blank"
                rel="noopener noreferrer"
              >
                View
              </a>
            </div>
            <div className={classNames(style.item3, style.gridCell)}>
              {/* <a
								href="https://marvel-game.netlify.app"
								target="_blank"
								rel="noopener noreferrer"
							> */}
              <img
                src={loadingGif}
                data-src={marvelGame}
                data-size="auto"
                className={classnames(
                  "disabled-project",
                  style.image,
                  "lazyload"
                )}
                alt="Project 7"
              />
              {/* </a> */}
              <h3 className={style.title}>Marvel Memory Game</h3>
              <p className={style.text}>
                Full Stack project (React and Node): Marvel Memory Game using
                the Marvel API (Currently offline due to Heroku closure)
              </p>
              {/* <a
                href="https://moviespy.netlify.app"
                target="_blank"
                rel="noopener noreferrer"
              > */}
							<div className={style.filler}>
                View
							</div>
              {/* </a> */}
            </div>
            <div className={classNames(style.item4, style.gridCell)}>
              <a
                href="https://moviespy.netlify.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={loadingGif}
                  data-src={movieSpy}
                  data-size="auto"
                  className={classnames(style.image, "lazyload")}
                  alt="Project 7"
                />
              </a>
              <h3 className={style.title}>Movie Spy</h3>
              <p className={style.text}>
                Frontend project (React, HTML5, SCSS, React Hooks, Webpack): A
                movie search app using the Movie Database API{" "}
              </p>
              <a
                href="https://moviespy.netlify.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                View
              </a>
            </div>
            {/* <div
							className={classNames(style.item5, style.gridCell)}
						>
							<a
								href="https://t9keyboard.netlify.app/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									src={loadingGif}
									data-src={projectSix}
									data-size="auto"
									className={classnames(
										style.image,
										"lazyload"
									)}
									alt="Project 3"
								/>
							</a>
							<h3 className={style.title}>
								T9 Phone Keyboard Emulator
							</h3>
							<p className={style.text}>
								An emulator of the old T9 Keyboards. Build with
								ES6. Frontend using React/Redux/Webpack. Backend
								using NodeJS, ExpressJS.
							</p>
							<a
								href="https://t9keyboard.netlify.app/"
								target="_blank"
								rel="noopener noreferrer"
							>
								View
							</a>
						</div> */}
            <div className={classNames(style.item5, style.gridCell)}>
              <a
                href="https://thecardmaker.netlify.app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={loadingGif}
                  data-src={projectTwo}
                  data-size="auto"
                  className={classnames(style.image, "lazyload")}
                  alt="Project 4"
                />
              </a>
              <h3 className={style.title}>Card Maker</h3>
              <p className={style.text}>
                A card maker to create custom game cards. Build using React,
                Redux, Webpack, Styled Components.
              </p>
              <a
                href="https://thecardmaker.netlify.app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                View
              </a>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Web;
